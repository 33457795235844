<template>
  <div class="homeWrap">
     
     <div class="bannerslider">
         <div class="swiper-container swiper-containerPC">
              <div class="swiper-wrapper w">
                   <div class="swiper-slide" v-for="(item,index) in list_pic" :key="index">
                       <a :href="utils.HandleUrl(item.linkUrl)" target="_blank" v-if="item.linkUrl">
                          <div class="img" >
                                <img :src="item.imgUrl">
                          </div>
                       </a>
                       <a href="javascript:void(0);" v-else>
                          <div class="img" >
                                <img :src="item.imgUrl">
                          </div>
                       </a>
                   </div>
              </div>
              <div class="swiper-pagination" v-if="list_pic.length>1"></div>
         </div>
         
         
        <div class="swiper-container swiper-containerM">
            <div class="swiper-wrapper w">
                   <div class="swiper-slide" v-for="(item,index) in list_picm" :key="index">
                       <a :href="utils.HandleUrl(item.linkUrl)" target="_blank" v-if="item.linkUrl">
                          <div class="img" >
                                <img :src="item.imgUrl">
                          </div>
                       </a>
                       <a href="javascript:void(0);" v-else>
                          <div class="img" >
                                <img :src="item.imgUrl">
                          </div>
                       </a>
                   </div>
              </div>
              <div class="swiper-pagination" v-if="list_picm.length>1"></div>
        </div>

     </div>


      <div class="home_page w h_about">
          <div class="h_about_info">
              <div class="companyInfos">
                  <div class="about_name">{{$t('header.about')}}</div>
                  <div class="about_con" v-html="CompanyInfo.aboutContent"></div>
                  <div class="view"><router-link  :to="{name:'about'}"><span>{{$t('home.aboutview')}}</span></router-link></div>
              </div>
              <div class="pic">
                  <img :src="CompanyInfo.aboutImg" alt="">
              </div>
          </div>
      </div>

      <!-- <div class="home_page h_pro">
           <div class="h_pro_info">
                <div class="tibar">{{$t('header.product')}}</div>
                  <div class="procategory fullScreen">
                    <div class="listShow">
                          <ul>
                            <template >
                                <li class="columns-item" v-for="(item,index) in list_procategory.slice(0,3)" :key="'pro_'+item.id">
                                  <router-link  :to="{name:'product',params:{cid:item.id}}">
                                    <div class="pic">
                                        <img :src="item.imgUrl" alt="">
                                    </div>
                                    <div class="infos">
                                        <div class="cate_name">
                                            {{item.title}}
                                        </div>
                                        <div class="cate_content">
                                            {{item.content}}
                                        </div>
                                        <div class="view"><span>{{$t('home.proview')}}</span></div>
                                    </div>
                                  </router-link>
                                </li>
                              </template>
                          </ul>
                    </div>
              </div>
           </div>
         
      </div> -->

      <!-- <div class="home_page h_case">
         <div class="h_case_info w">
             <div class="tibar">{{$t('header.cases')}}</div>
             <div class="Casecategory">
                 <div class="listShow clearfix">
                     <ul>
                         <template>
                              <li class="columns-item" v-for="(item, index) in list_casecategory.slice(0,4)" :key="'case_'+item.id">
                                  <router-link  :to="{name:'cases',params:{cid:item.id}}">
                                   <div class="infos">
                                        <div class="ico">
                                            <img :src="item.imgUrl">
                                        </div>
                                        <div class="name">{{item.title}}</div>
                                        <div class="num">0{{index+1}}</div>
                                   </div>
                                   <div class="showCon">
                                      <div class="img">
                                          <img :src="item.imgUrl2">
                                      </div>
                                      <div class="name">{{item.title}}</div>
                                   </div>
                                  </router-link>
                              </li>
                         </template>
                     </ul>
                 </div>
            </div> 
         </div>
      </div> -->

      <div class="home_page w h_news">
         <div class="h_news_info">
            <div class="tibar">{{$t('header.news')}}</div>
            <div class="h_newsBox clearfix">
                <div class="h_hotNew_left">
                    <div class="newsList clearfix">
                      <ul>
                        <template v-for="(item,index) in list_news1">
                          <li class="clearfix" :key="'article_'+item.id" v-if="index==0">
                            <router-link :to="{name:'newsdetail',params:{id:item.id}}">
                              <div class="img">
                                <img :src="item.imgUrl" alt="">
                              </div>
                              <div class="info">
                                <h3>{{item.title}}</h3>
                                <div class="desc">
                                  {{item.zhaiyao}}
                                </div>
                                <div class="pull">
                                  <span class="time">{{item.publishTime}}</span>
                                  <span class="more">{{$t('home.newsview')}}</span>
                                </div>
                              </div>
                            </router-link>
                          </li>
                        </template>
                      </ul>
                    </div>
                </div>
                <div class="h_hotNew_right">
                    <div class="newsList clearfix">
                      <ul>
                        <template v-for="(item,index) in list_news2.slice(0,3)">
                          <li class="clearfix" :key="'article_'+item.id">
                            <router-link :to="{name:'newsdetail',params:{id:item.id}}">
                              <div class="img">
                                <img :src="item.imgUrl" alt="">
                              </div>
                              <div class="info">
                                <h3>{{item.title}}</h3>
                                <div class="desc">
                                  {{item.zhaiyao}}
                                </div>
                                <div class="pull">
                                  <span class="time">{{item.publishTime}}</span>
                                  <span class="more">{{$t('home.newsview')}}</span>
                                </div>
                              </div>
                            </router-link>
                          </li>
                        </template>
                      </ul>
                    </div>
                </div>
            </div>
            <div class="more"><router-link :to="{ name: 'newslist' }">{{$t('home.newsview1')}}</router-link></div>
         </div>
      </div>

  </div>
</template>
<script>
import {mapState} from 'vuex'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
export default {
  data() {
    return {
       list_procategory:[],
       list_casecategory:[],
       list_pic:[],
       list_picm:[],
       list_news1:[],
       list_news2:[],
    }
  },

  computed:{
    ...mapState({
      CompanyInfo:state=>state.CompanyInfo
    })
  },
  created: function () {
     this.GetProcategory();
     this.GetCasecategory();
     this.GetAdList();
     this.GetAdListM();
     this.InitNewSwiper();
  },
  mounted(){
    this.GetNews("1", "list_news1");
    this.GetNews("2", "list_news2");
  },
  components: {
    
  },
  methods: {
     GetNews: function (_tag, _name) {
        var url = "/pageapi/news/getlist";
         this.utils.Loading();
            this.$http.post(url, {CategoryId: _tag}).then(function (res) {
             this.utils.LoadEnd();
            if (res.data.status == 1) {
                 this[_name] = res.data.ds;
              } else {
                Toast(res.data.msg);
              }
            }.bind(this)
          )
          .catch(function (error) {});
     },
    GetProcategory() {
        var url = "/pageapi/product/getdata";
        this.utils.Loading();
        this.$http.post(url, {noList:1}).then(function (res) {
              this.utils.LoadEnd();
              if (res.data.status == 1) {
                this.list_procategory = res.data.ds;
              } else {
                Toast(res.data.msg);
              }
            }.bind(this)
          )
          .catch(function (error) {});
      },
      GetCasecategory(){
        var url = "/pageapi/cases/getdata";
        this.utils.Loading();
        this.$http.post(url, {noList:1}).then(function (res) {
              this.utils.LoadEnd();
              if (res.data.status == 1) {
                this.list_casecategory = res.data.ds;
              } else {
                Toast(res.data.msg);
              }
            }.bind(this)
          )
          .catch(function (error) {});
      },
      GetAdList(){
         var url = "/pageapi/Advert/GetList";
        this.utils.Loading();
        this.$http.post(url, { CategoryId: 1}).then(function (res) {
              this.utils.LoadEnd();
              if (res.data.status == 1) {
                this.list_pic = res.data.ds;
                this.$nextTick(function () {
                    this.InitNewSwiper();
                });
              } else {
                Toast(res.data.msg);
              }
              }.bind(this)
          ).catch(function (error) {});
      },
      GetAdListM(){
         var url = "/pageapi/Advert/GetList";
        this.utils.Loading();
        this.$http.post(url, { CategoryId: 3}).then(function (res) {
              this.utils.LoadEnd();
              if (res.data.status == 1) {
                this.list_picm = res.data.ds;
                this.$nextTick(function () {
                    this.InitADMSwiper();
                });
              } else {
                Toast(res.data.msg);
              }
              }.bind(this)
          ).catch(function (error) {});
      },
      InitNewSwiper(){
             var mySwiper = new Swiper ('.swiper-containerPC', {
                loop: false,
                allowTouchMove: false,
                speed: 600,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false
                },
                pagination: {
                    el: ".swiper-pagination",
                },
             })
            },
      InitADMSwiper(){
          var mySwiper = new Swiper ('.swiper-containerM', {
                loop: false,
                allowTouchMove: false,
                speed: 600,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false
                },
                pagination: {
                    el: ".swiper-pagination",
                },
             })
      }
  },
}
</script>