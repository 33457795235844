import Vue from 'vue'
// 导入路由插件
import Router from 'vue-router'
// 安装路由
Vue.use(Router);
// 配置路由

export default new Router({
  routes: [{
      path: '/',
      component: require('@/views/Layout').default,
      children: [
    
        {
          path: '',
          name: 'home',
          component: require('@/views/Home').default,
          meta: {
            nav: 'home'
          }
        },
        {
          path: 'about',
          name: 'about',
          component: require('@/views/About').default,
          meta: {
            nav: 'about'
          }
        },
        {
          path: 'product/:cid?/:id?',
          name: 'product',
          component: require('@/views/Product/Product').default,
          meta: {
            nav: 'product'
          }
        },
        {
          path: 'cases/:cid?/:id?',
          name: 'cases',
          component: require('@/views/Cases/Cases').default,
          meta: {
            nav: 'cases'
          }
        },
        {
          path: 'technical',
          name: 'technical',
          component: require('@/views/Technical').default,
          meta: {
            nav: 'technical'
          }
        },
        {
          path: 'newslist/:id?',
          name: 'newslist',
          component: require('@/views/News/NewsList').default,
          meta: {
            nav: 'news',
            keepAlive: true,
            detailPath:'newsdetail'
          }
        },
        {
          path: 'newsdetail/:id',
          name: 'newsdetail',
          component: require('@/views/News/NewsDetail').default,
          meta: {
            nav: 'news'
          }
        },
        {
          path: 'contact',
          name: 'contact',
          component: require('@/views/Contact').default,
          meta: {
            nav: 'contact'
          }
        },
        
      ]
    },
   
  ]
});

