<template>
  <div id="app" :class="[$route.name]">
    <keep-alive>
    <router-view v-if="$route.meta.keepAlive" ref="routeAlive">
        <!-- 这里是会被缓存的视图组件，比如 Home！ -->
    </router-view>
    </keep-alive>

    <router-view v-if="!$route.meta.keepAlive">
        <!-- 这里是不被缓存的视图组件，比如 Edit！ -->
    </router-view>
  </div>
</template>

<script>
export default{
  watch:{
    $route(to,from){
      if (this.$refs.routeAlive){
        const cache = this.$refs.routeAlive.$vnode.parent.componentInstance.cache;
        const keys = this.$refs.routeAlive.$vnode.parent.componentInstance.keys;
        const waitDelKeys = [];
        if (from.meta.detailPath!=to.name){
          keys.forEach((k) => {
              waitDelKeys.push(k);
          })
        }
        // 销毁缓存
        waitDelKeys.forEach((wk) => {
          const index = keys.indexOf(wk)
          keys.splice(index, 1)
          delete cache[wk]
        })
      }
    }
  },
}
</script>