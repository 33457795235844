<template>
  <div :class="['container',lang]">
      <Header></Header>
      <div class="main">
        <router-view></router-view>
      </div>
      <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/Common/Header.vue';
import Footer from '../components/Common/Footer.vue';
export default {
  data(){return{
    lang: window.localStorage.getItem('lang') || 'zh'
  }},
  mounted () {
  },
  components:{
    Header,
    Footer
  },
}
</script>
