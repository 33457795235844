<template>
    <div class="innerPage">
        <div class="innerBaner case">
             <div class="text_title w">{{$t('case.title')}}</div>
        </div>
        <div class="categoryShow">
          <div class="navigation case w">
              <template v-for="(item,index) in list">
                  <router-link  :class="[current_cat_index==index?'curr':'']" :to="{name:'cases',params:{cid:item.id}}"><span><em>{{item.title}}</em></span></router-link>
              </template>
          </div>
        </div>
        <div v-if="list[current_cat_index]" class="productDescShow w">


          <div v-if="list[current_cat_index].list.length>0" :class="['product-wrap', list[current_cat_index].list.length <2 ? 'noslide' : '']">
            <template>
              <el-carousel trigger="click" arrow="always" indictator-posiion="outside" :interval="0" @change="OnCarouselChange" ref="productRef">
                <el-carousel-item v-for="item in list[current_cat_index].list" :key="item.id">
                  <div class="productConent">
                    <div class="" v-if="item.imgUrl">
                      <PicSwiperCom :imgurl="item.imgUrl" :loop="false" :autoplay="false" ></PicSwiperCom>
                    </div>
                    <div class="pro_descBox">
                        <div class="proName_c2">{{item.title}}</div>
                        <div class="pro_textCon" v-html="item.content"></div>
                    </div>
                    
                  </div>
                </el-carousel-item>
              </el-carousel>
            </template>

          </div>



          <div class="product-nav case" v-if="list[current_cat_index].list.length>1">
            <template v-for="(item,index) in list[current_cat_index].list">
                <a :class="[current_pro_index==index?'curr':'']" @click="ClickPro(item,index)"><span>{{item.title}}</span></a>
            </template>
          </div>

        </div>
    </div>
  </template>
  <script>
  import { Carousel,CarouselItem } from 'element-ui';
  import PicSwiperCom from '../../components/Common/PicSwiperCom.vue'
  export default {
    data() {
      return {
        list:[],
        cid:this.$route.params.cid,
        current_cat_index:-1,
        current_pro_index:0,
      }
    },
    created: function () {
     this.GetData();
    },
    mounted:function(){
     } ,
    components: {
      [Carousel.name]:Carousel,
      [CarouselItem.name]:CarouselItem,
      PicSwiperCom
    },
    methods: {
      GetData() {
        var url = "/pageapi/cases/getdata";
        this.utils.Loading();
        this.$http
          .post(url, {
          })
          .then(
            function (res) {
              this.utils.LoadEnd();
              if (res.data.status == 1) {
                this.list = res.data.ds;
                this.GetCurrent();
              } else {
                Toast(res.data.msg);
              }
            }.bind(this)
          )
          .catch(function (error) {});
      },
      GetCurrent(){
        for (var i=0;i<this.list.length;i++){
          if (this.cid==this.list[i].id){
            this.current_cat_index=i;
            return;
          }
        }
        if (this.list.length>0){
          this.current_cat_index=0;
          return;
        }
        this.current_cat_index=-1;
      },
      OnCarouselChange(toindex,fromindex){
        this.current_pro_index=toindex;
      },
      ClickPro(_item,_index){
        this.current_pro_index=_index;
        this.$refs.productRef.setActiveItem(_index);
      }
    },
    watch:{
      $route(){
        this.cid=this.$route.params.cid;
        this.GetCurrent();
      }
    }
  }
  </script>

 