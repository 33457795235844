<template>
    <div class="page_item" v-if="is_show">
        <span class="pre btn"><a @click="JumpPage(pageindex-1)">上一页</a></span>
        <span class="num"><a @click="JumpPage(1)" :class="{curr:pageindex==1}">1</a></span>
        <span class="num" v-if="head_more"><a>...</a></span>
        <span class="num" v-for="(item,index) in list" :key="'page'+index"><a @click="JumpPage(item)" :class="{curr:pageindex==item}">{{item}}</a></span>
        <span class="num" v-if="end_more"><a>...</a></span>
        <span class="num"><a @click="JumpPage(page_count)" :class="{curr:pageindex==page_count}">{{page_count}}</a></span>
        <span class="next btn"><a @click="JumpPage(pageindex+1)">下一页</a></span>
    </div>
</template>

<script>
export default {
    props: {
        total: {
            type: Number
        },
        value: {
            type: Number,
            default:1
        },
        pagesize: {
            type: Number,
            default: 10
        },
        btn_count: {
            type: Number,
            default:9
        },
        is_white:{
            type:Boolean,
            default:false
        }
    },
    data(){return{
        pageindex:this.value,
        page_count: 0,
        is_show:false,
        head_more: false,
        end_more:false,
        start_page: 2,
        has_pre: true,
        has_next: true,
        list: [],
    }},
    created:function(){
        this.Cal();
    },
    methods: {
        Cal:function(){
            this.is_show=false;
            this.list=[];
            this.head_more=false;
            this.end_more=false;
            if (this.pagesize == 0) {
                return;
            }
            this.page_count = parseInt(this.total / this.pagesize);
            if (this.total % this.pagesize != 0) {
                this.page_count++;
            }
            if (this.page_count < 2) {
                return;
            }
            if (this.btn_count < 3) this.btn_count = 3;
            this.is_show = true;
            var show_btn_count=this.btn_count;
            if (this.page_count > this.btn_count) {
                var left_count = parseInt((this.btn_count-2) / 2);
                var right_count = left_count;
                if (this.btn_count % 2 != 0) left_count++;
                var left_index = this.pageindex - left_count + 1;
                var right_index = this.pageindex + right_count;
                if (left_index <= 2) {
                    var to_right = 2 - left_index;
                    this.start_page = 2;
                    this.end_more = true;
                } else if (right_index >= this.page_count - 1) {
                    var to_left = right_index - this.page_count + 1;
                    this.start_page = left_index - to_left;
                    this.head_more = true;
                } else {
                    this.start_page = left_index;
                    this.head_more = true;
                    this.end_more = true;
                }
            }else{
                show_btn_count=this.page_count;
            }
            for (var i = 0; i < show_btn_count-2; i++) {
                this.list.push(this.start_page+i);
            }
        },
        JumpPage: function (_page) {
            if (!this.CanJump(_page)) {
                return;
            } else {
                this.pageindex=_page;
            }
        },
        CanJump: function (_page) {
            if (_page < 1 || _page > this.page_count) return false;
            if (_page == this.pageindex) return false;
            return true;
        }
    },
    watch:{
        total(newVal){
            this.Cal();
        },
        pageindex(newVal) {
            this.$emit('input', newVal);
            this.$emit('pagechange');
            this.Cal();
            document.documentElement.scrollTop = 0;
        },
        value(newVal){
            this.pageindex=newVal;
        }
    }
}
</script>