<template>
   <div class="innerPage">
       <div class="innerBaner technical w">
             <div class="text_title">
                <span class="title">{{$t('technical.title')}}</span>
                <span class="tip">{{$t('technical.tips')}}</span>
              </div>
        </div>
        <div class="technicalShow w">
            <div class="listShow">
                 <ul class="clearfix">
                  <template  v-for="(item,index) in list">
                     <li :key="index">
                       <img :src="item.imgUrl">
                       <div class="name">{{item.title}}</div>
                    </li>
                  </template>
                  
                 </ul>
            </div>
        </div>
    </div>
  </template>
<script>
import {mapState} from 'vuex'
export default {
  data() {
    return {
      list:[]
    }
  },
  computed:{
    ...mapState({
      CompanyInfo:state=>state.CompanyInfo
    })
  },
  created: function () {
    this.GetList();
  },
  mounted(){
  },
  components: {
   
  },
  methods: {
     GetList() {
         var url = "/pageapi/technology/getlist";
               this.utils.Loading();
               this.$http.post(url, { CategoryId: 2}).then(function (res) {
                     this.utils.LoadEnd();
                     if (res.data.status == 1) {
                        this.list = res.data.ds;
                     } else {
                        Toast(res.data.msg);
                     }
                     }.bind(this)
                  )
                  .catch(function (error) {});
      },
  }
}
</script>