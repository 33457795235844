<template>
    <div class="innerPage">
        <div class="innerBaner product w">
             <div class="text_title">
                <span class="title">{{$t('product.title')}}</span>
                <span class="tip">{{$t('product.tips')}}</span>
              </div>
        </div>
        <div class="categoryShow pro w">
          <div class="navigation">
              <template v-for="(item,index) in list">
                  <router-link  :class="[current_cat_index==index?'curr':'']" :to="{name:'product',params:{cid:item.id}}">{{item.title}}</router-link>
              </template>
          </div>
        </div>
        <div v-if="list[current_cat_index]" class="productDescShow w">
          <div class="cat-wrap" :style="{'background-image':'url('+list[current_cat_index].imgUrl+')'}">
              <div class="cat-conShow">
                <!-- <div class="pro_name">{{ list[current_cat_index].title }}</div>
                <div class="pro_info">{{ list[current_cat_index].content }}</div> -->
              </div>
          </div>

          <!-- <div class="pro_video" v-if="list[current_cat_index]&&list[current_cat_index].videoPath">
             <div class="videoShow">
                  <iframe width="1010" height="576"  style="border:none;" :src="list[current_cat_index].videoPath"></iframe>
             </div>
          </div> -->

          <!-- <div class="subtitle" v-if="list[current_cat_index].subTitle">
              <h1>{{ list[current_cat_index].subTitle }}</h1>
          </div> -->

          
          <div class="product_tab" v-if="list[current_cat_index].list.length>1">
            <div class="product-nav clearfix">
              <template v-for="(item,index) in list[current_cat_index].list">
                  <a :class="[current_pro_index==index?'curr':'']" @click="ClickPro(item,index)"><span>{{item.title}}</span></a>
              </template>
            </div>
          </div>


          <div  v-if="list[current_cat_index].list.length>0" :class="['product-wrap', list[current_cat_index].list.length <2 ? 'noslide' : '']">
            <template>
              <el-carousel  trigger="click"  arrow="always" indictator-posiion="outside" :interval="0" @change="OnCarouselChange" ref="productRef">
                <el-carousel-item v-for="item in list[current_cat_index].list" :key="item.id" >
                  <template v-if="item.content">
                        <div class="productConent">
                          <template v-if="item.imgUrl">
                          <div class="proPicBox">
                              <PicSwiperCom :imgurl="item.imgUrl" :loop="false" :autoplay="false" ></PicSwiperCom>
                          </div>
                          <div class="pro_descBox">
                              <div class="proName_c2">{{item.title}}</div>
                              <div class="pro_textCon" v-html="item.content"></div>
                          </div>
                          </template>
                          <template v-else>
                          <div class="pro_descBox autotext">
                              <div class="proName_c2">{{item.title}}</div>
                              <div class="pro_textCon" v-html="item.content"></div>
                          </div>
                          </template>
                      </div>
                  </template>
                  <template v-else>
                    <div class="productConent">
                      <div class="typicalShowPC">
                          <TypicalSwiperPc :imgurl="item.imgUrl" :loop="false" :autoplay="false" ></TypicalSwiperPc>
                      </div>
                      <div class="typicalShowM">
                          <TypicalSwiperM :imgurl="item.imgUrl" :loop="false" :autoplay="false" ></TypicalSwiperM>
                      </div>
                    </div>
                  </template>
                </el-carousel-item>
                
              </el-carousel>
            </template>

          </div>

          <div class="pro_meter" v-if="list[current_cat_index].imgExtra">
            <img :src="list[current_cat_index].imgExtra" alt="">
          </div>

        </div>
    </div>
  </template>
  <script>
  import { Carousel,CarouselItem } from 'element-ui';
  import PicSwiperCom from '../../components/Common/PicSwiperCom.vue'
  import TypicalSwiperPc from '../../components/Common/TypicalSwiperPc.vue'
  import TypicalSwiperM from '../../components/Common/TypicalSwiperM.vue'
  export default {
    data() {
      return {
        list:[],
        cid:this.$route.params.cid,
        current_cat_index:-1,
        current_pro_index:0,

      }
    },
    created: function () {
      this.GetData();
    },
    
    mounted:function(){
     
     },

    components: {
      [Carousel.name]:Carousel,
      [CarouselItem.name]:CarouselItem,
      PicSwiperCom,
      TypicalSwiperPc,
      TypicalSwiperM,
    },
    methods: {
      GetData() {
        var url = "/pageapi/product/getdata";
        this.utils.Loading();
        this.$http
          .post(url, {
          })
          .then(
            function (res) {
              this.utils.LoadEnd();
              if (res.data.status == 1) {
                this.list = res.data.ds;
                this.GetCurrent();
              } else {
                Toast(res.data.msg);
              }
            }.bind(this)
          )
          .catch(function (error) {});
      },
      GetCurrent(){
        this.current_pro_index=0;
        for (var i=0;i<this.list.length;i++){
          if (this.cid==this.list[i].id){
            this.current_cat_index=i;
            return;
          }
        }
        if (this.list.length>0){
          this.current_cat_index=0;
          return;
        }
        this.current_cat_index=-1;
      },
      OnCarouselChange(toindex,fromindex){
        this.current_pro_index=toindex;
      },
      ClickPro(_item,_index){
        this.current_pro_index=_index;
        this.$refs.productRef.setActiveItem(_index);
      },

    },
    watch:{
      $route(){
        this.cid=this.$route.params.cid;
        this.GetCurrent();
      }
    }
  }
  </script>

 <style scoped>
   
 </style>