<template>
    <div class="footer_box">
        <div class="w">
             <div class="foot_nav_tem fot1">
                  <div class="f_nav_li">
                    <ul>
                       <li class="first"><router-link :to="{ name: 'home' }">{{$t('header.home')}}</router-link></li>
                       <li><router-link :to="{ name: 'about' }">{{$t('header.about')}}</router-link></li>
                       <li> <router-link :to="{ name: 'product'}">{{$t('header.product')}}</router-link></li>
                       <li><router-link :to="{ name: 'technical' }">{{$t('header.technical')}}</router-link></li>
                       <li><router-link :to="{ name: 'newslist' }">{{$t('header.news')}}</router-link></li>
                       <li class="last"><router-link :to="{ name: 'contact' }">{{$t('header.contact')}}</router-link></li>
                    </ul>
                  </div>
             </div>
             <div class="foot_contact fot2">
                 <div class="weChat_code">
                      <img :src="CompanyInfo.qrImg">
                 </div>
                 <div class="contact_info">
                     <div class="item phone">
                          <div class="ico">
                             <img src="../../assets/images/icon_footer_a1.png">
                          </div>
                          <div class="text_con">
                               <span class="tibar">{{$t('footer.phone')}}:</span>
                               <span class="con">{{CompanyInfo.tel}}</span>
                          </div>
                     </div>
                     <div class="item mail">
                          <div class="ico">
                             <img src="../../assets/images/icon_footer_a2.png">
                          </div>
                          <div class="text_con">
                               <span class="tibar">{{$t('footer.mail')}}:</span>
                               <span class="con">{{CompanyInfo.email}}</span>
                          </div>
                     </div>
                     <div class="item address">
                          <div class="ico">
                             <img src="../../assets/images/icon_footer_a3.png">
                          </div>
                          <div class="text_con">
                               <span class="tibar">{{$t('footer.address')}}:</span>
                               <span class="con">{{CompanyInfo.address}}</span>
                          </div>
                     </div>
                 </div>
             </div>
             <div class="foot_copyright fot3">
                 <span>{{$t('footer.copyright')}} {{CompanyInfo.companyName}}</span>
                 <span><a href="https://beian.miit.gov.cn/" target="_blank"></a>{{$t('footer.icp')}}</span>
                 <span>
                    <a target="_blank" class="bottom-words ga_link" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32059002004521">
                        <span class="ga-words">苏公网安备32059002004521号</span>
                    </a>
                </span>
             </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
     data(){return{
          CompanyInfo:{},
     }},
  created(){
     this.GetInfo();
  },
  methods:{
     ...mapActions([
      'setCompanyInfo'
    ]),
    GetInfo() {
        var url = "/pageapi/common/GetCompanyInfo";
        this.$http
          .post(url, {
          })
          .then(
            function (res) {
              if (res.data.status == 1) {
                this.CompanyInfo = res.data.ds;
                this.setCompanyInfo(res.data.ds);
              }
            }.bind(this)
          )
          .catch(function (error) {});
      },
  }
}
</script>