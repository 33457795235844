<template>
    <div class="innerPage">
        <div class="innerBaner news w">
             <div class="text_title">
                <span class="title">{{$t('news.title')}}</span>
                <!-- <span class="tip">{{$t('product.tips')}}</span> -->
              </div>
        </div>
        <div class="categoryShow w">
             <div class="navigation">
                  <router-link :class="[id==1?'curr':'']" :to="{name:'newslist',params:{id:1}}">{{$t('news.category1')}}</router-link>
                  <router-link :class="[id==2?'curr':'']" :to="{name:'newslist',params:{id:2}}">{{$t('news.category2')}}</router-link>
                  <!-- <a href="javascript:void(0)" :class="is_newstab==0?'curr':''"  @click="switnewschTab(0)">{{$t('news.category1')}}</a>
                  <a href="javascript:void(0)" :class="is_newstab==1?'curr':''"  @click="switnewschTab(1)">{{$t('news.category2')}}</a> -->
             </div>
        </div>
        <div class="main_body w">
          <div class="article_box">
           <div class="article_title" v-if="id==1">{{$t('news.category1')}}</div>
           <div class="article_title" v-else>{{$t('news.category2')}}</div>
                <NewsListCom  :CategoryId="id"></NewsListCom>
            </div>
        </div>
    </div>
  </template>
  <script>
  import NewsListCom from '../../components/News/NewsListCom.vue';
  export default {
    data() {
      return {
        id:this.$route.params.id || 1,
      }
    },
    created: function () {
     
    },
    mounted:function(){
      document.documentElement.scrollTop = 0;
     },
    components: {
        NewsListCom
    },
    methods: {
      switnewschTab: function (_type,_tab_type) {
         this.is_newstab = _type;
      },
    },
   watch: {
     $route(newVal,oldVal){
        if (this.$route.name=="newslist"){
          this.id=this.$route.params.id || 1;
        }
     }
  }
  }
  </script>