/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
 import axios from 'axios';
 import Qs from 'qs'
 import ApiSign from './ApiSign';
 
 const tip=(tips)=>{
    console.log(tips);
 }
 /** 
  * 请求失败后的错误统一处理 
  * @param {Number} status 请求失败的状态码
  */
 const errorHandle = (status, other) => {
     // 状态码判断
     switch (status) {
         case 404:
             tip('请求的资源不存在'); 
             break;
         default:
             console.log(other);
         }}
 
         const appid='22156701';
         const secret='yW3h9vP6HqD5SE7N';
 // 创建axios实例
 var instance = axios.create({timeout: 1000 * 12});
 if (!process.env.npIS_WEB) instance.defaults.withCredentials = true;
 // 设置post请求头
 instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

 /** 
  * 请求拦截器 
  * 每次请求前，如果存在token则在请求头中携带token 
  */ 
 instance.interceptors.request.use(
     config => {
         // 登录流程控制中，根据本地是否存在token判断用户的登录情况        
         // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token        
         // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码        
         // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
         if (config.method==='post'&&!config.isfile){
             config.data=Qs.stringify(config.data);

             var timestamp=ApiSign.GetTimeStamp();
             var nonce=ApiSign.GetNonce();
             config.headers.appid=appid;
             config.headers.timestamp=timestamp;
             config.headers.nonce=nonce;
             var signature = ApiSign.GetSign(config.data,appid,secret,timestamp,nonce);
             config.headers.signature=signature;
         }
         config.headers.version="580";
         config.headers.fromsys="yunjing";
         config.headers.lang=window.localStorage.getItem('lang') || 'zh';
         return config;
     },    
     error => Promise.error(error))
 
 // 响应拦截器
 instance.interceptors.response.use(
     // 请求成功
     res => {
         if (res.status===200){
            return Promise.resolve(res);
         }else{
             return Promise.reject(res);
         }
         //return res.status === 200 ? Promise.resolve(res) : Promise.reject(res);
     },    
     // 请求失败
     error => {
         const { response } = error;
         if (response) {
             // 请求已发出，但是不在2xx的范围 
             errorHandle(response.status, response.data.message);
             return Promise.reject(response);
         } else {
             // 处理断网的情况
             // eg:请求超时或断网时，更新state的network状态
             // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
             // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
             //store.commit('changeNetwork', false);
             tip("网络请求超时");
         }
     });
 
 export default instance;