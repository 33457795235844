<template>
   <div class="innerPage">
       <div class="innerBaner contact w">
             <div class="text_title">
                <span class="title">{{$t('contact.title')}}</span>
              </div>
        </div>
        <div class="contactDetail w">
            <div class="contactConShow">
               <div class="c_item phone">
                  {{ $t('contact.phone') }}：{{ CompanyInfo.tel }}
               </div>
               <div class="c_item email">
                  {{ $t('contact.email') }}：{{ CompanyInfo.email }}
               </div>
               <div class="c_item address">
                   {{ $t('contact.address') }}：{{ CompanyInfo.address }}
               </div>
            </div>
            <div class="c_mapShow">
                <h1> {{ $t('contact.navigation') }}</h1>
                <div class="mapCon">
                    <img :src="CompanyInfo.mapNavigation" alt="">
                </div>
            </div>
        </div>
    </div>
  </template>
<script>
import {mapState} from 'vuex'
export default {
  data() {
    return {
     
    }
  },
  computed:{
    ...mapState({
      CompanyInfo:state=>state.CompanyInfo
    })
  },
  created: function () {
   
  },
  mounted(){
  },
  components: {
   
  },
  methods: {

  }
}
</script>