<template>
<div>
    <div class="header_box"  id="h_target" :class="{'fixed':isfixed}">
      <div class="w clearfix">
      <div class="logo">
        <router-link :to="{ name: 'home' }">
            <img src="../../assets/logo.png">
        </router-link>
      </div>
      <div class="mobile-inner-header-icon" @click="shownav()"></div>
      <div class="mobile-inner-nav">
          <div class="item1"  @click="hidenav()">
            <router-link :to="{ name: 'home' }" :class="['item', $route.meta.nav == 'home' ? 'curr' : '']">{{$t('header.home')}}</router-link>
          </div>
           <div class="item1"  @click="hidenav()">
             <router-link :to="{ name: 'about' }" :class="['item', $route.meta.nav == 'about' ? 'curr' : '']" @click="hidenav()">{{$t('header.about')}}</router-link>
          </div>
           <div class="item1"  @click="hidenav()">
              <router-link :to="{ name: 'product' }" :class="['item', $route.meta.nav == 'product' ? 'curr' : '']">{{$t('header.product')}}</router-link>
          </div>
           <div class="item1"  @click="hidenav()">
             <router-link :to="{ name: 'technical' }" :class="['item', $route.meta.nav == 'technical' ? 'curr' : '']">{{$t('header.technical')}}</router-link>
          </div>
           <div class="item1"  @click="hidenav()">
              <router-link :to="{ name: 'newslist' }" :class="['item', $route.meta.nav == 'news' ? 'curr' : '']">{{$t('header.news')}}</router-link>
          </div> 
             <div class="item1"  @click="hidenav()">
             <router-link :to="{ name: 'contact' }" :class="['item', $route.meta.nav == 'contact' ? 'curr' : '']">{{$t('header.contact')}}</router-link>
          </div> 
           
            
      </div>
      <div class="tabbar">
        <router-link :to="{ name: 'home' }" :class="['item', $route.meta.nav == 'home' ? 'curr' : '']">{{$t('header.home')}}</router-link>
        <router-link :to="{ name: 'about' }" :class="['item', $route.meta.nav == 'about' ? 'curr' : '']">{{$t('header.about')}}</router-link>
        <router-link :to="{ name: 'product' }" :class="['item', $route.meta.nav == 'product' ? 'curr' : '']">{{$t('header.product')}}</router-link>
        <router-link :to="{ name: 'technical' }" :class="['item', $route.meta.nav == 'technical' ? 'curr' : '']">{{$t('header.technical')}}</router-link>
        <router-link :to="{ name: 'newslist' }" :class="['item', $route.meta.nav == 'news' ? 'curr' : '']">{{$t('header.news')}}</router-link>
        <router-link :to="{ name: 'contact' }" :class="['item', $route.meta.nav == 'contact' ? 'curr' : '']">{{$t('header.contact')}}</router-link>
      </div>
      <LangCom></LangCom>
    </div>
    </div>
  </div>
</template>

<script>
import LangCom from './LangCom.vue'
import $ from 'jquery';
export default {
  data() {
    return {
      isfixed: false,
      targetTop: 0,
      // dropdownActive: false,
    };
  },
  components:{
    LangCom
  },
   mounted() {

    //  $(".mobile-inner-header-icon").click(function () {
    //   console.log(11111)
    //       $(".mobile-inner-nav").slideToggle();
    //   });
    //   $(".mobile-inner-nav a").click(function () {
    //       $(".mobile-inner-nav").hide();
    //   });
      window.addEventListener("scroll", this.scrolling)
      this.targetTop = document.querySelector('#h_target').offsetTop
  },
   methods: {
      shownav(){
          $(".mobile-inner-nav").slideToggle();
      },
      hidenav(){
        $(".mobile-inner-nav").hide();
      },
      scrolling() {
          let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
          if (scrollTop > this.targetTop) {
              this.isfixed = true;
          } else {
              this.isfixed = false;
          }
      },
  },


};
</script>
<st