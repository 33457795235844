<template>
    <div class="innerPage">
       <div class="innerBaner w about">
            <div class="text_title w">
               <span class="title">{{$t('about.title')}}</span>
            </div>
        </div>
         <div class="categoryShow about w">
            <div class="navigation">
                <a href="javascript:void(0);" @click="goAnchor('#introduction')">{{$t('about.introduction')}}</a>
                <a href="javascript:void(0);" @click="goAnchor('#course')">{{$t('about.course')}}</a>
                <a href="javascript:void(0);" @click="goAnchor('#staff')">{{$t('about.staff')}}</a>
                <a href="javascript:void(0);" @click="goAnchor('#honor')">{{$t('about.honor')}}</a>
                <a href="javascript:void(0);" @click="goAnchor('#partners')">{{$t('about.partners')}}</a>
            </div>
         </div>
         <div class="aboutDesc">
              <div class="companyDesc" id="introduction">
                   <div class="companyCon w">
                        <div class="companyPic" v-if="CompanyInfo.companyIntroImg">
                              <img :src="CompanyInfo.companyIntroImg">
                        </div>
                        <div class="companyTextShow">
                             <div class="companyTibar">{{$t('about.introduction')}}</div>
                             <div class="contentShow" v-html="CompanyInfo.companyIntro">
                             </div>
                        </div>
                   </div>
              </div>
               <div class="courseDesc w" id="course">
                  <div class="courseCon">
                      <div class="tibar">{{$t('about.course')}}</div>
                      <div class="courseTextShow clearfix">
                           <div class="item">
                               <div class="textCon">
                                  <div class="name">{{CompanyInfo.licheng1Title}}</div>
                                  <div class="con">{{CompanyInfo.licheng1Content}}</div>
                                </div>
                           </div>
                           <div class="item">
                              <div class="textCon">
                                <div class="name">{{CompanyInfo.licheng2Title}}</div>
                                <div class="con">{{CompanyInfo.licheng2Content}}</div>
                              </div>
                           </div>
                      </div>
                  </div>
              </div>
              <div class="staffDesc" id="staff" v-if="CompanyInfo.albums">
                   <div class="staffCon w">
                      <div class="tibar">{{$t('about.staff')}}</div>
                      <div class="listShow clearfix">
                          <ul>
                            <template v-for="item in CompanyInfo.albums.split(',')">
                            <li>
                              <a href="javascript:void(0);">
                                  <img :src="item">
                              </a>
                            </li>
                          </template>
                          </ul>
                      </div>
                   </div>
              </div>
              <div class="honorShow w" id="honor" v-if="CompanyInfo.honor">
                  <div class="tibar">{{$t('about.honor')}}</div>
                  <div class="honorList">
                     <viewer  class="list clearfix">
                        <div class="item"  v-for="(item,index) in CompanyInfo.honor.split(',')" :key="index">
                              <img :src="item" >
                        </div>
                     </viewer>
                  </div>
              </div>
              <div class="partnersDesc" id="partners">
                  <div class="partnersCon w">
                      <div class="tibar">{{$t('about.partners')}}</div>
                      <div class="listShow clearfix">
                          <ul>
                           <template v-for="(item,index) in list_partner">
                                 <li :key="index">
                                    <a :href="utils.HandleUrl(item.linkUrl)" target="_blank" v-if="item.linkUrl">
                                       <span>
                                          <img :src="item.imgUrl">
                                       </span>
                                    </a>
                                     <a href="javascript:void(0);" v-else>
                                       <span>
                                          <img :src="item.imgUrl">
                                       </span>
                                    </a>
                                 </li>
                             </template>
                          </ul>
                      </div>
                  </div>
              </div>
         </div>
    </div>
  </template>

  <script>
  import {Image} from 'element-ui'

  import {mapState} from 'vuex'
  export default {
    data() {
      return {
         list_partner:[],
      }
    },
    computed:{
      ...mapState({
        CompanyInfo:state=>state.CompanyInfo
      })
    },
   created: function () {
      this.GePartnerList();
   },
    mounted(){
       document.documentElement.scrollTop = 0;
    },
    components: {
       [Image.name]:Image,

    },
    methods: {
       GePartnerList() {
         var url = "/pageapi/Advert/GetList";
               this.utils.Loading();
               this.$http.post(url, { CategoryId: 2}).then(function (res) {
                     this.utils.LoadEnd();
                     if (res.data.status == 1) {
                        this.list_partner = res.data.ds;
                     } else {
                        Toast(res.data.msg);
                     }
                     }.bind(this)
                  )
                  .catch(function (error) {});
      },

       goAnchor(selector) {
          this.$el.querySelector(selector).scrollIntoView({
            behavior: "smooth", 
            block: "center", 
            
          });
      },

    }
  }
  </script>