
export default {
  header: {
    home: 'Home',
    about: 'About Us',
    product: 'Product&Services',
    technical:'Technical',
    news: 'News',
    contact: 'Contact',
  },
  footer: {
    phone: 'Phone',
    mail:'E-Mail',
    address:'Address',
    address1:'1F, Building 18, Gangtian Industrial Building, No. 99 Gangtian Road, Suzhou Industrial Park',
    company:'Copyright© Suzhou Yunjing Metal Technology Co., Ltd',
    copyright:'Copyright©',
    icp:'苏ICP备2021041698号-1',
  },
  home:{
    proview:'Learn more',
    aboutview:'Detail',
    newsview:'DISCOVER MORE >>',
    newsview1:'MORE'
  },
  about:{
    title:'About Yunjing',
    introduction:'Company Profile',
    course:'Yunjing Culture',
    staff:'STAFF',
    honor:'Honorary Qualifications',
    partners:'Partners'
},
  product:{
    title:'Products and Services',
    tips:'Yunjing Technology has provided tens of thousands of products to over 160 customers.'
  },
  case:{
    title:'Application Cases',
  },
  technical:{
    title:'Technical Capabilities',
    tips:'Yunjing Technology can provide a one-stop service from powder research and development to the final delivery of mold products',
    pro_name1:'Powder design and development',
    pro_name2:'Printing parameter optimization',
    pro_name3:'Design and simulation',
    pro_name4:'3D Printing',
    pro_name5:'Heat treatment',
    pro_name6:'Fine machining',
    pro_name7:'Paint-coat',
    pro_name8:'Hot Isostatic Pressing',
  },
  news:{
    title:'News',
    source:'Source',
    date:'Date',
    category1:'Yunjing News',
    category2:'Industry News',
    view:'MORE'
  },
  contact:{
    title:'Contact Us',
    phone:'Phone',
    email:'Email',
    address:"Address",
    navigation:'Map navigation',
 }
}