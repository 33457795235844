import $ from 'jquery'
import { Toast } from 'vant';
var utils={
    DefaultAvatar:'http://m.xincailiao.com/images/my/def_avatar.png',
    DefaultImg:'http://m.xincailiao.com/backstage/skin/default/loadimg.gif',
    DefaultProImg:'https://m.xincailiao.com/images/company_icon/com_logo.png',
    IsDevelopment(){
        return process.env.NODE_ENV == "development";
    },
    HandleUrl(_url){
        if (_url===undefined) return '';
        if (_url.indexOf('http')!=0){
            return 'http://'+_url;
        }
        return _url;
    },
    //_type:0头像，1封面 2项目
    HandleImg(_img_url,_nodefault,_type){
        if (_img_url!==undefined&&_img_url.indexOf('/backstage/skin/default/loadimg.gif')>-1){_img_url='';}
        if (_img_url!==undefined&&_img_url!=='') {
            return this.HandleUrl(_img_url);
        }else{
            if (_nodefault) return "";
            else{
                if (_type===1){
                    return this.DefaultImg;
                }else if (_type===2){
                    return this.DefaultProImg;
                }else{
                    return this.DefaultAvatar;
                }
            }
        }
    },
    HandleContentImg(_content){
        if (_content===undefined) return '';
        var reg = /src="(\/upload\/[^\"]*)"/g
        _content = _content.replace(reg, 'src="http://m.xincailiao.com$1"');
        return _content;
    },
    HandleLocalImage(_img_url) {
        if (_img_url === undefined||_img_url==='') return '';
        if (process.env.NODE_ENV !== "development") return _img_url;

        if (_img_url.indexOf('http')!=0){
            return 'http://localhost:2360'+_img_url;
        }
        return _img_url;
    },
    HandleLocalContentImg(_content) {
        if (_content === undefined||_content==='') return '';
        if (process.env.NODE_ENV !== "development") return _content;
        var reg = /src="(\/upload\/[^\"]*)"/g
        _content = _content.replace(reg, 'src="http://localhost:2360$1"');
        return _content;
    },
    GetFileSize(_size){
        var strSize="";
        if (_size>(1024*1024)){
            strSize=(_size/1024/1024).toFixed(2)+"M";
        }else{
            strSize=(_size/1024).toFixed(2)+"KB";
        }
        return strSize;
    },
    GetCurrentTime(){
        var date = new Date();
        var date_str = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes();
        return date_str;
    },
    // DateFormat(_date, fmt) {
    //     var o = {
    //       "M+": _date.getMonth() + 1, //月份 
    //       "d+": _date.getDate(), //日 
    //       "h+": _date.getHours(), //小时 
    //       "m+": _date.getMinutes(), //分 
    //       "s+": _date.getSeconds(), //秒 
    //       "q+": Math.floor((_date.getMonth() + 3) / 3), //季度 
    //       "S": _date.getMilliseconds() //毫秒 
    //     };
    //     if (/(y+)/.test(fmt)) {
    //       fmt = fmt.replace(RegExp.$1, (_date.getFullYear() + "").substr(4 - RegExp.$1.length));
    //     }
    //     for (var k in o) {
    //       if (new RegExp("(" + k + ")").test(fmt)) {
    //         fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    //       }
    //     }
    //     return fmt;
    //   },
    DateFormat(fmt, date) {
        let ret;
        const opt = {
            "Y+": date.getFullYear().toString(),        // 年
            "m+": (date.getMonth() + 1).toString(),     // 月
            "d+": date.getDate().toString(),            // 日
            "H+": date.getHours().toString(),           // 时
            "M+": date.getMinutes().toString(),         // 分
            "S+": date.getSeconds().toString()          // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            };
        };
        return fmt;
    },
    GetRamId(){
        return (Math.random() * 10000000).toString(16).substr(0, 4) + '-' + (new Date()).getTime() + '-' + Math.random().toString().substr(2, 5);
    },
    //返回顶部  sudu为速度值，1最慢
    BackTop(sudu){
        let timer = null;
        cancelAnimationFrame(timer);
        timer = requestAnimationFrame(function fn() {
            var oTop = document.body.scrollTop || document.documentElement.scrollTop;
            if (oTop > 0) {
                scrollTo(0, oTop - sudu);
                timer = requestAnimationFrame(fn);
            } else {
                cancelAnimationFrame(timer);
            }
        });
    },
    //下拉自动加载 参数：执行函数 偏移量
    AddLoadMoreListener(_callback,_offset){
        var offset = 10;
        if(_offset != undefined && _offset > 0){
            offset = _offset;
        }
        
        if(typeof _callback === 'function'){
            var fun =function () { 
                //判断是否滚动到底部
                var scrollTop = document.documentElement.scrollTop;
                if(scrollTop === 0){
                    scrollTop = $(document).scrollTop();
                }
                if(scrollTop + window.innerHeight + offset >= $(document).height()){
                    _callback();
                }
            }
            //注册scroll事件并监听
            window.addEventListener('scroll',fun);
            return fun;
        }
    },
    //将2022-12-27 00:00:00字符串数组转化为2022-12-27日期字符串数组
    ConvertDateList(_list){
        var _this=this;
        var _temp=[];
        _list.map(function(_item){
            _temp.push(_this.DateFormat("YYYY-mm-dd",new Date(_item)));
        });
        return _temp;
    },
    Loading(_noClick,_overlay){
        //_noClick:是否禁止背景点击  _overlay:是否显示背景遮罩层
        Toast.loading({
            duration:0,
            message:"加载中",
            loadingType: 'spinner',
            forbidClick:_noClick,
            overlay:_overlay
        });
    },
    LoadEnd(){
        Toast.clear();
    },
}


export default utils;  