import Vue from 'vue'
import App from './App.vue'
import router from './router'
import http from './utils/http'
import utils from './utils/utils';
import './assets/css/layout.css';
import './assets/css/base.css';
import './assets/css/mobilebase.css';
import './utils/rem.js';
import './assets/fonts/fonts.css';
import i18n from './i18n'
import store from './store'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.config.productionTip = false
Vue.http = Vue.prototype.$http = http
Vue.prototype.utils = utils

new Vue({
  render: h => h(App),
  router,
  store,
  i18n,
}).$mount('#app')

Vue.use(Viewer)

Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})


// const flag = navigator.userAgent.match(
//   /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
// );

// if (flag) {
//   console.log(1)
//   require('@/assets/css/mobilebase.css');
// }else{
//   console.log(2)
//   require('@/assets/css/base.css');
// }