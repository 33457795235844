<template>
    <div>
        <template v-if="list_pic.length>0">
            <div :id="ramid" class="swiper-container">
                <div class="swiper-wrapper">
                     <div class="img_row swiper-slide" v-for="(item,index) in list_pic" :key="index">
                          <img :src="item" >
                     </div>
                </div>
                 <div class="swiper-pagination" v-if="list_pic.length>1"></div>
            </div>
        </template>
        <template v-else>
            <div class="pro_picShow">
                <img :src="imgurl" alt="">
            </div>
        </template>
    </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
export default{
    props:{
         imgurl:{},
         loop:{
            Type:Boolean,
            default:true
         },
         autoplay:{
            Type:Boolean,
            default:true
         }
    },
    data() {
        return {
            ramid: (Math.random() * 10000000).toString(16).substr(0, 4) + '-' + (new Date()).getTime() + '-' + Math.random().toString().substr(2, 5),
            list_pic:{},
        }
    },
    created(){
        if (this.imgurl){
            if (this.imgurl.indexOf(",")>0){
                this.list_pic=this.imgurl.split(",");
            }
        }
    },
    methods:{
        InitScript() {
        let str = '#' + this.ramid;
        this.$nextTick(()=>{
            this.swiper = new Swiper(str, {
            loop: this.loop,
            speed: 600,
            nested:true,
            autoplay: this.autoplay?{
                delay: 2000,
                disableOnInteraction: false
            }:false,
            pagination: {
                el: ".swiper-pagination",
            },
            });
        });
    },
    },
    watch:{
     list_pic:{
      handler(){
        if (this.list_pic.length>0){
           this.$nextTick(() => {
            this.InitScript();
          });
        }
      },
      immediate:true,
     }
  }
}
</script>
