<template>
    <div class="lang">
        <a href="javascript:void(0);" @click="changeLangauge('zh')" :class="{curr:language=='zh'}">中</a>
        / <a href="javascript:void(0);" @click="changeLangauge('en')" :class="{curr:language=='en'}">EN</a>
    </div>
</template>

<script>
export default{
    data(){return{
        language:'',
    }},
    created(){
        this.showLangauge();
    },
    methods:{
        showLangauge() {
            const currentLang = window.localStorage.getItem('lang') || 'zh'
            switch (currentLang) {
                case 'zh':
                this.language = 'zh'
                break
                case 'en':
                this.language = 'en'
                break
            }
        },
        changeLangauge(lang) {
            this.$i18n.locale = lang
            window.localStorage.setItem('lang', lang)
            window.location.reload()
        }
    }
}
</script>