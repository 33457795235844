<template>
    <div>
      <div class="newsList clearfix">
        <div class="no_data_tip_li" v-if="total==0">
          <img src="static/images/error_tip.png" alt="">
        </div>
        <ul>
          <template v-for="(item,index) in list">
            <li class="clearfix" :key="'article_'+item.id">
              <router-link :to="{name:'newsdetail',params:{id:item.id}}">
                <div class="img">
                  <img :src="item.imgUrl" alt="">
                </div>
                <div class="info">
                  <h3>{{item.title}}</h3>
                  <div class="desc">
                    {{item.zhaiyao}}
                  </div>
                  <div class="pull">
                    <span class="read">{{item.click}}</span>
                    <span class="time">{{item.publishTime}}</span>
                  </div>
                </div>
                <div class="more"><span>{{$t('news.view')}}</span></div>
              </router-link>
            </li>
          </template>
        </ul>
      </div>
      <Pagination v-model="pageindex" :total="total" :pagesize="pagesize" />
    </div>
  </template>
  
  <script>
  import { Toast } from "vant";
  import Pagination from "../Common/Pagination";
  
  import { mapState } from "vuex";
  export default {
    data() {
      return {
        list: [],
        pageindex: 1,
        pagesize: 6,
        total: 0,
      };
    },
    computed: {
      ...mapState("User", {
        user_info: (state) => state.user_info,
      }),
    },
    props: [
      "value",
      "keyword",
      "CategoryId",
    ],
    components: {
      Pagination,
    },
    created() {
      if (this.set_pagesize > 0) {
        this.pagesize = this.set_pagesize;
      }
      this.GetList();
    },
    methods: {
      GetList() {
        var url = "/pageapi/news/getlist";
        this.utils.Loading();
        this.$http
          .post(url, {
            pageindex: this.pageindex,
            pagesize: this.pagesize,
            keyword: this.keyword,
            CategoryId: this.CategoryId,
          })
          .then(
            function (res) {
              this.utils.LoadEnd();
              if (res.data.status == 1) {
                this.list = res.data.ds;
                this.total = res.data.total;
                this.$emit("input", res.data.total);
              } else {
                Toast(res.data.msg);
              }
            }.bind(this)
          )
          .catch(function (error) {});
      },
      PropsChange() {
        if (this.pageindex !== 1) this.pageindex = 1;
        else this.GetList();
      },
    },
    watch: {
      pageindex: function () {
        this.GetList();

      },
      keyword() {
        this.PropsChange();
      },
      CategoryId() {
        console.log(this.CategoryId);
        this.PropsChange();
      },
    },
  };
  </script>