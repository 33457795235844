export default {
    header: {
      home: '首页',
      about: '关于匀晶',
      product: '产品与服务',
      technical:'技术能力',
      news: '新闻资讯',
      contact: '联系我们',
    },
    footer: {
      phone: '电话',
      mail:'邮箱',
      address:'地址',
      address1:'苏州市工业园区港田路99号港田工业坊18幢1F',
      copyright:'版权所有©',
      icp:'苏ICP备2021041698号-1',
    },
    home:{
      proview:'了解详情',
      aboutview:'查看详情',
      newsview:'查看更多 >>',
      newsview1:'查看更多'
    },
    about:{
        title:'关于匀晶',
        introduction:'公司简介',
        course:'匀晶文化',
        staff:'员工风采',
        honor:'荣誉资质',
        partners:'合作伙伴'
    },
    product:{
      title:'产品与服务',
      tips:'匀晶科技已为160+客户，提供了数万余件产品。'
    },
    case:{
      title:'应用案例',
    },
    technical:{
      title:'技术能力',
      tips:'匀晶科技可提供从粉末研发到模具最终交付产品的一站式服务',
      pro_name1:'粉末设计及研发',
      pro_name2:'打印参数优化',
      pro_name3:'设计与仿真',
      pro_name4:'3d打印',
      pro_name5:'热处理',
      pro_name6:'精加工',
      pro_name7:'涂层',
      pro_name8:'热等静压',
    },
    news:{
      //new_url:require('../assets/images/account.png')
       title:'新闻资讯',
       source:'来源',
       date:'日期',
       category1:'匀晶新闻',
       category2:'行业新闻',
       view:'查看更多'
    },
    contact:{
       title:'联系我们',
       phone:'电话',
       email:'邮箱',
       address:"地址",
       navigation:'地图导航',
    }
}