<template>
    <div class="innerPage">
       <div class="innerBaner news w">
            <div class="text_title">
                  <span class="title">{{$t('news.title')}}</span>
                <span class="tip">{{$t('product.tips')}}</span>
          </div>
        </div>
        <div class="newsDetail w">
             <div class="title">
                 <h1>{{dto.title}}</h1>
             </div>
             <div class="article_meta">
                 <span v-if="dto.source">{{$t('news.source')}}：{{ dto.source }}</span>
                 <span>{{$t('news.date')}}：{{ dto.publishTime }}</span>
             </div>
             <div class="article_content" v-html="dto.content"></div>
        </div>
    </div>
  </template>
  <script>
  import { Toast } from 'vant';
  export default {
    data() {
      return {
       dto:{}
      }
    },
    created: function () {
     this.GetDetail();
    },
    mounted:function(){
     },
    components: {
    },
    methods: {
        GetDetail() {
        var url = "/pageapi/news/getdetail";
        this.utils.Loading();
        this.$http
          .post(url, {
            id:this.$route.params.id
          })
          .then(
            function (res) {
              this.utils.LoadEnd();
              if (res.data.status == 1) {
                this.dto = res.data.ds;
              } else {
                Toast(res.data.msg);
              }
            }.bind(this)
          )
          .catch(function (error) {});
      },
    }
  }
  </script>